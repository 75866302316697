import axios from '@axios'

export default {
  namespaced: true,
  state: {
    respData: null,
    respDataView: null,
  },
  getters: {
  },
  mutations: {
    GET(state, payload) {
      state.respData = payload
    },
    VIEW(state, payload) {
      state.respDataView = payload
    },
    ADD(state, payload) {
      state.respData.data.push(payload)
    },
    UPDATE(state, payload) {
      const productIndex = state.respData.data.findIndex(p => p._id === payload._id)
      Object.assign(state.respData.data[productIndex], payload)
    },
    REMOVE(state, id) {
      if (state.respData != null) {
        const ItemIndex = state.respData.data.findIndex(p => p._id === id)
        state.respData.data.splice(ItemIndex, 1)
        // eslint-disable-next-line operator-assignment
        state.respData.max = state.respData.max - 1
      }
    },
  },
  actions: {
    get({ commit }, item) {
      return new Promise((resolve, reject) => {
        // console.log(item.sortField)
        // console.log(item.sortDirection)
        let url = `/api/web/application/eCommerce/menu/getSubListMenus?id=${item.id}&pageSize=${item.pageSize}&currentPage=${item.currentPage}&searchQuery=${item.searchQuery}`
        if (item.sortField && item.sortDirection) {
          url = url + `&sortField=${item.sortField}&sortDirection=${item.sortDirection}`
        }
        axios.get(url)
          .then(response => {
            commit('GET', response.data)
            resolve(response)
          })
          .catch(error => {
            reject(error)
          })
      })
    },
    view({ commit }, item) {
      return new Promise((resolve, reject) => {
        axios.get(`/api/web/application/eCommerce/menu/viewSubPageMenus?id=${item.id}`)
          .then(response => {
            commit('VIEW', response.data.data)
            resolve(response)
          })
          .catch(error => {
            reject(error)
          })
      })
    },
    // eslint-disable-next-line no-unused-vars
    add({ commit }, item) {
      return new Promise((resolve, reject) => {
        axios.post('/api/web/application/eCommerce/menu/addSubPageMenus', item)
          .then(response => {
            commit('ADD', response.data.data)
            resolve(response)
          })
          .catch(error => {
            reject(error)
          })
      })
    },
    // eslint-disable-next-line no-unused-vars
    update({ commit }, item) {
      return new Promise((resolve, reject) => {
        axios.put(`/api/web/application/eCommerce/menu/editSubPageMenus?id=${item.id}`, item)
          .then(response => {
            // commit('UPDATE', response.data.data)
            resolve(response)
          })
          .catch(error => {
            reject(error)
          })
      })
    },
    // eslint-disable-next-line no-unused-vars
    delete({ commit }, item) {
      return new Promise((resolve, reject) => {
        axios.put('/api/web/application/eCommerce/menu/deleteSubMenus', item)
          .then(response => {
            commit('REMOVE', item.id)
            resolve(response)
          })
          .catch(error => {
            reject(error)
          })
      })
    },
    // eslint-disable-next-line no-unused-vars
    send({ commit }, item) {
      return new Promise((resolve, reject) => {
        axios.put('/api/web/application/eCommerce/menu/sendNotiSubMenus', item)
          .then(response => {
            // commit('REMOVE', item.id)
            resolve(response)
          })
          .catch(error => {
            reject(error)
          })
      })
    },
    // eslint-disable-next-line no-unused-vars
    uploadImage({ commit }, item) {
      return new Promise((resolve, reject) => {
        axios.put('/api/web/application/eCommerce/menu/uploadImage', item)
          .then(response => {
            resolve(response)
          })
          .catch(error => {
            reject(error)
          })
      })
    },
  },
}
