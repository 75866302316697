<template>
  <div v-if="$ability.can('read', this.$route.meta.resource)">
    <b-overlay :show="show" rounded="sm">
      <b-card no-body class="mb-0">
        <div class="m-2">
          <b-row>
            <b-col>
              <label>{{ $t("Show") }}</label>
              <v-select
                v-model="perPage"
                :dir="$store.state.appConfig.isRTL ? 'rtl' : 'ltr'"
                :options="perPageOptions"
                :clearable="false"
                class="per-page-selector v-select-custom"
                @input="pageSize"
              />
              <label for="">{{ $t("entries") }}</label>
            </b-col>

            <b-col cols="12" md="6" class="b-col-custom">
              <div class="d-flex align-items-center justify-content-end">
                <b-form-input
                  v-model="searchQuery"
                  class="d-inline-block mr-1"
                  :placeholder="$t('Search') + '...'"
                  @input="typing($event)"
                />
                <b-button
                  v-ripple.400="'rgba(255, 255, 255, 0.15)'"
                  variant="primary"
                  class="d-flex"
                  @click="sidebarAddNew()"
                >
                  <feather-icon icon="PlusIcon" />
                  <!-- <span class="align-middle">Add Users</span> -->
                </b-button>
                <!-- <b-form-file
                @change="importFileJson"
                placeholder="Choose a file or drop it here..."
                drop-placeholder="Drop file here..."
                accept=".json"
              /> -->
              </div>
            </b-col>
          </b-row>
        </div>
        <b-table
          ref="refUserListTable"
          striped
          responsive
          class="position-relative"
          :items="respData"
          :fields="tableColumns"
          primary-key="_id"
          show-empty
          :empty-text="$t('No matching records found')"
          style="height: 60vh;"
          :sort-by="sortBy"
          :sort-desc="sortDesc"
          @sort-changed="sortChanged"
        >
          <!-- Column: Images -->
          <template #cell(image)="data">
            <b-row>
              <b-img
                v-if="data.item.image"
                :src="data.item.image"
                fluid
                thumbnail
                width="100px"
                :alt="'Users_' + data.item._id"
              />
              <b-img
                v-else
                :src="require('@/assets/images/blank/no_image.png')"
                fluid
                thumbnail
                width="100px"
                :alt="'Users_' + data.item._id"
              />
            </b-row>
          </template>
          <!-- Column: User -->
          <template #cell(name)="data">
            <p
              v-for="(item, index) in data.item.name"
              :key="index"
              class="align-middle ml-50"
            >
              {{ item.lang.toUpperCase() }}: {{ (item.value) }}
            </p>
          </template>
          <template #cell(created)="data">
            <b-row>
              <p>{{ data.item.created | formatDateTime }}</p>
            </b-row>
          </template>
          <!-- Column: Action -->
          <template #cell(action)="data">
            <b-dropdown
              variant="link"
              no-caret
              :right="$store.state.appConfig.isRTL"
            >
              <template #button-content>
                <feather-icon
                  icon="MoreVerticalIcon"
                  size="16"
                  class="align-middle text-body"
                />
              </template>
              <b-dropdown-item @click="openSubMenu(data.item)">
                <feather-icon icon="EditIcon" />
                <span class="align-middle ml-50">{{ $t("Edit") }}</span>
              </b-dropdown-item>
              <b-dropdown-item @click="sendData(data.item._id)">
                <feather-icon icon="BellIcon" />
                <span class="align-middle ml-50">{{ $t("Send Notify") }}</span>
              </b-dropdown-item>
              <b-dropdown-item @click="deleteData(data.item._id)">
                <feather-icon icon="TrashIcon" />
                <span class="align-middle ml-50">{{ $t("Delete") }}</span>
              </b-dropdown-item>
            </b-dropdown>
          </template>
        </b-table>
        <div class="mx-2 mb-2">
          <b-row>
            <b-col
              cols="12"
              sm="6"
              class="d-flex align-items-center justify-content-center justify-content-sm-start"
            >
              <span class="text-muted"
                >{{ currentPage * perPage - (perPage - 1) }} -
                {{
                  queriedItems - currentPage * perPage > 0
                    ? currentPage * perPage
                    : queriedItems
                }}
                {{ $t("of") }} {{ queriedItems }}</span
              >
            </b-col>
            <!-- Pagination -->
            <b-col
              cols="12"
              sm="6"
              class="d-flex align-items-center justify-content-center justify-content-sm-end"
            >
              <b-pagination
                v-model="currentPage"
                :total-rows="queriedItems"
                :per-page="perPage"
                first-number
                last-number
                class="mb-0 mt-1 mt-sm-0"
                prev-class="prev-item"
                next-class="next-item"
                @input="nextPage"
              >
                <template #prev-text>
                  <feather-icon icon="ChevronLeftIcon" size="18" />
                </template>
                <template #next-text>
                  <feather-icon icon="ChevronRightIcon" size="18" />
                </template>
              </b-pagination>
            </b-col>
          </b-row>
        </div>
      </b-card>
    </b-overlay>
  </div>
</template>

<script>
import {
  BCard,
  BRow,
  BCol,
  BImg,
  BAvatar,
  BFormInput,
  BButton,
  BTable,
  BPagination,
  BOverlay,
  BCardText,
  BDropdown,
  BDropdownItem,
} from "bootstrap-vue";
import vSelect from "vue-select";
import Ripple from "vue-ripple-directive";
import store from "@/store";
import perPageOptions from "@/perPageOptions";
// import { useToast } from 'vue-toastification/composition'
import ToastificationContent from "@core/components/toastification/ToastificationContent.vue";
// import { ref } from '@vue/composition-api'
import { avatarText } from "@core/utils/filter";
import storeModule from "@/store/services/eCommerce/subMenu";
import debounce from "debounce";

const STORE_MODULE_NAME = "subMenu";

export default {
  components: {
    BCard,
    BRow,
    BCol,
    // eslint-disable-next-line vue/no-unused-components
    BImg,
    // eslint-disable-next-line vue/no-unused-components
    BAvatar,
    BFormInput,
    BButton,
    BTable,
    BPagination,
    BDropdown,
    BDropdownItem,
    BOverlay,
    // eslint-disable-next-line vue/no-unused-components
    BCardText,

    // BFormFile,
    // BBadge,
    // BLink,
    // BMedia,
    // BAvatar,
    // eslint-disable-next-line vue/no-unused-components
    vSelect,
  },
  directives: {
    Ripple,
  },
  data() {
    return {
      avatarText,
      currentPage: 1,
      searchQuery: "",
      dataId: null,
      perPage: localStorage.getItem("itemsPerPage")
        ? localStorage.getItem("itemsPerPage")
        : 30,
      isSidebarActive: false,
      sidebarData: {},
      show: false,
      sortBy: null,
      sortDesc: null,
      perPageOptions,
    };
  },
  computed: {
    tableColumns() {
      return [
        { key: "orderByMenu", label: this.$t("Order By Menu"), sortable: true },
        // { key: 'image', label: this.$t('Image'), sortable: false },
        { key: "name", label: this.$t("Name"), sortable: false },
        { key: "title", label: this.$t("Title"), sortable: false },
        { key: "created", label: this.$t("Created"), sortable: true },

        // { key: 'firstName', label: this.$t('FirstName'), sortable: false },
        // { key: 'lastName', label: this.$t('LastName'), sortable: false },
        { key: "action", label: this.$t("ACTION"), sortable: false },
      ];
    },
    respData() {
      return store.state[STORE_MODULE_NAME].respData != null
        ? store.state[STORE_MODULE_NAME].respData.data
        : [];
    },
    queriedItems() {
      return store.state[STORE_MODULE_NAME].respData != null
        ? store.state[STORE_MODULE_NAME].respData.max
        : 0;
    },
  },
  created() {
    const Id = this.$route.params.id; // ContactID
    this.dataId = Id;
    // Register module
    if (!store.hasModule(STORE_MODULE_NAME))
      store.registerModule(STORE_MODULE_NAME, storeModule);
    this.get();
    this.typing = debounce(this.typing, 500);
  },
  destroyed() {
    if (store.hasModule(STORE_MODULE_NAME))
      store.unregisterModule(STORE_MODULE_NAME);
  },
  setup() {
    return { STORE_MODULE_NAME };
  },
  methods: {
    get() {
      console.log(this.sortBy);
      console.log(this.sortDesc);
      const obj = {
        id: this.dataId,
        currentPage: this.currentPage,
        pageSize: this.perPage,
        searchQuery: this.searchQuery,
        sortField: this.sortBy,
      };
      if (this.sortBy !== null) {
        obj.sortField = this.sortBy;
      }
      if (this.sortDesc !== null) {
        obj.sortDirection = this.sortDesc === true ? "desc" : "asc";
      }

      this.show = true;
      store
        .dispatch(`${STORE_MODULE_NAME}/get`, obj)
        // eslint-disable-next-line no-unused-vars
        .then((result) => {
          const { menu } = result.data;
          this.sortBy = menu.sortField;
          this.sortDesc = menu.sortDirection === "desc";
          console.log(this.sortBy);
          console.log(this.sortDesc);
          this.show = false;
          // this.showToast('success', 'top-right', 'fetch Success', 'CheckIcon')
          // eslint-disable-next-line no-unused-vars
        })
        .catch((error) => {
          this.show = false;
          this.$toast({
            component: ToastificationContent,
            position: "bottom-right",
            props: {
              title: this.$t("Error"),
              icon: "ErrorIcon",
              variant: "danger",
              text: this.$t(error.response.data.message),
            },
          });
          // console.log('fetchUsers Error : ', error)
          // this.showToast('danger', 'top-right', 'FetchUsers Error ', 'XCircleIcon')
        });
    },
    sortChanged(e) {
      console.log(e);
      this.sortBy = e.sortBy;
      this.sortDesc = e.sortDesc;
      this.currentPage = 1;
      this.get();
    },
    openSubMenu(data) {
      this.show = true;

      this.$bvModal
        .msgBoxConfirm(`${this.$t("Do you want to")} ${this.$t("Sub Menu")}?`, {
          title: this.$t("Please Confirm"),
          cancelVariant: "outline-secondary",
          okVariant: "success",
          okTitle: this.$t("Yes"),
          cancelTitle: this.$t("No"),
          centered: true,
        })
        .then((value) => {
          this.show = false;

          if (value === true) {
            this.$router.push({
              path: `/e-commerce/menu/sub/${this.dataId}/${data._id}`,
            });
          }
          //  else {
          //   this.showToast(
          //     'warning',
          //     'top-right',
          //     `${this.$t('DELETE_CANCEL')}!`,
          //     'AlertCircleIcon',
          //     this.$t('DELETE_CANCEL'),
          //   )
          // }
        });
    },
    typing(value) {
      console.log(value);
      this.searchQuery = value;
      this.currentPage = 1;
      this.get();
    },
    sidebarAddNew() {
      this.$router.push({ path: `/e-commerce/menu/sub/${this.dataId}/add` });
    },
    deleteData(id) {
      this.$bvModal
        .msgBoxConfirm(`${this.$t("Do you want to delete")}?`, {
          title: this.$t("Please Confirm"),
          cancelVariant: "outline-secondary",
          okVariant: "danger",
          okTitle: this.$t("Yes"),
          cancelTitle: this.$t("No"),
          centered: true,
        })
        .then((value) => {
          if (value === true) {
            this.show = true;
            const obj = { id };
            store
              .dispatch(`${STORE_MODULE_NAME}/delete`, obj)
              .then((result) => {
                this.show = false;
              })
              .catch((error) => {
                this.show = false;
                console.log("fetchUsers Error : ", error);
                this.$toast({
                  component: ToastificationContent,
                  position: "bottom-right",
                  props: {
                    title: this.$t("Error"),
                    icon: "ErrorIcon",
                    variant: "danger",
                    text: this.$t(error.response.data.message),
                  },
                });
              });
          }
          //  else {
          //   this.showToast(
          //     'warning',
          //     'top-right',
          //     `${this.$t('DELETE_CANCEL')}!`,
          //     'AlertCircleIcon',
          //     this.$t('DELETE_CANCEL'),
          //   )
          // }
        });
    },
    sendData(id) {
      this.$bvModal
        .msgBoxConfirm(`${this.$t("Do you want to send")}?`, {
          title: this.$t("Please Confirm"),
          cancelVariant: "outline-secondary",
          okVariant: "success",
          okTitle: this.$t("Yes"),
          cancelTitle: this.$t("No"),
          centered: true,
        })
        .then((value) => {
          if (value === true) {
            this.show = true;
            const obj = { id };
            store
              .dispatch(`${STORE_MODULE_NAME}/send`, obj)
              .then((result) => {
                this.show = false;
              })
              .catch((error) => {
                this.show = false;
                console.log("fetchUsers Error : ", error);
                this.$toast({
                  component: ToastificationContent,
                  position: "bottom-right",
                  props: {
                    title: this.$t("Error"),
                    icon: "ErrorIcon",
                    variant: "danger",
                    text: this.$t(error.response.data.message),
                  },
                });
              });
          }
          //  else {
          //   this.showToast(
          //     'warning',
          //     'top-right',
          //     `${this.$t('DELETE_CANCEL')}!`,
          //     'AlertCircleIcon',
          //     this.$t('DELETE_CANCEL'),
          //   )
          // }
        });
    },
    pageSize(size) {
      localStorage.setItem("itemsPerPage", size);
      this.perPage = size;
      this.currentPage = 1;
      this.get();
    },
    nextPage(page) {
      this.currentPage = page;
      this.get();
    },
    showToast(variant, position, text, icon, title) {
      this.$toast(
        {
          component: ToastificationContent,
          props: {
            title,
            icon,
            text,
            // icon: 'InfoIcon',
            // text: 'I do not think that word means what you think it means.',
            variant,
          },
        },
        {
          position,
        }
      );
    },
  },
};
</script>

<style lang="scss">
@import "@core/scss/vue/libs/vue-select.scss";
.v-select-custom {
  display: inline-block;
  width: 50;
  margin-left: 0.5rem;
  margin-right: 0.5rem;
}
.b-col-custom {
  margin-top: 0.5rem;
}
</style>
